/* eslint-disable import/no-unresolved */
import { graphql, useStaticQuery } from 'gatsby'

// Language
import { getLanguage } from 'services/language'

const vacatureInfo = {
  get() {
    const language = getLanguage()

    const {
      vacatureInfoNL,
      vacatureInfoEN
     } = useStaticQuery(graphql`
      {
        vacatureInfoNL: wordpressPage(wordpress_id: {eq: 592}) {
          ...vacatureInfoContent
        }
  
        vacatureInfoEN: wordpressPage(wordpress_id: {eq: 607}) {
          ...vacatureInfoContent
        }
      }
  
      fragment vacatureInfoContent on wordpress__PAGE {
        path

        acf {
          overview {
            read_more_title
            load_more_title
            back_button_title
          }
        }
      }
    `)
  
    return language === 'nl_NL' ? vacatureInfoNL : vacatureInfoEN
  }
}

export default vacatureInfo