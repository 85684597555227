/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import ParseContent from 'components/shared/ParseContent'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import GravityForm from 'components/GravityForm'

// Elements
import BlockDefault from 'components/elements/BlockDefault'
import HeroAlt from 'components/elements/HeroAlt'

// Icons
import Next from 'img/icon/next-main.inline.svg'

// Data
import vacatureInfo from 'src/graphql/vacatureInfo'

// Helpers
import { getLanguage } from 'services/language'

// Third Party
import parse from 'html-react-parser'

const Container = styled.div`
  position: relative;
  z-index: 2;

  @media (min-width: 1200px) {
    /* max-width: 900px; */
  }
`

const Title = styled.h1`
  font-weight: ${props => props.theme.font.weight.xl};
  color: ${props => props.contrast ? props.theme.color.text.light : props.theme.color.text.contrast};

  @media (min-width: 992px) {
    font-size: ${props => props.theme.font.size.xxl};
  }

  @media (max-width: 991px) {
    font-size: ${props => props.theme.font.size.l};
  }
`

const BackButton = styled(Link)`
  font-size: ${props => props.theme.font.size.sm};
  font-weight: ${props => props.theme.font.weight.l};
  color: ${props => props.theme.color.text.main};
  border: 2px solid ${props => props.theme.color.text.main};
  padding: 8px 15px;
  border-radius: 25px;

  & svg {
    height: 20px;
    width: 20px;
    transform: rotate(180deg);
    margin-right: 10px;
    position: relative;
    bottom: 2px;
  }

  &:hover {
    color: ${props => props.theme.color.text.main};
    text-decoration: underline;
  }
`

const Stripe = styled.div`
  background-color: ${props => props.contrast ? props.theme.color.text.light : props.theme.color.text.main};
  height: 1px;
  width: 100%;
`

const Contact = styled.div`
  margin-top: 150px;

  @media screen and (max-width: 991px) {
    margin-top: 50px;
  }
`

const FormBlock = styled(BlockDefault)`
  background-color: ${props => props.theme.color.contrast};
`

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressWpVacatures(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      
      acf {
        banner {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920)
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        meta {
          description
          contact_title
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, title, yoast_meta: yoast, acf },
  },
}) => {

  const language = getLanguage()
  const vacatureFields = vacatureInfo.get()

  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
      />

      <section>
        <HeroAlt small image={acf.banner.image} />
      </section>

      <section className="mt-n5 pb-5">
        <Container className="container mt-n5">
          <div className="row mb-lg-5">
            <div className="col-lg-7">
              <BlockDefault>
                <div>
                  <BackButton to={vacatureFields.path}>
                    <Next />
                    {vacatureFields.acf.overview.back_button_title}
                  </BackButton>
                </div>
                <div className="px-lg-4 mt-5">
                  <Title>
                    {parse(title)}
                  </Title>
                </div>
                <div className="px-lg-2 mt-4 mt-lg-5">
                  <Stripe />
                </div>
                <div className="px-lg-4 mt-4 mt-lg-5">
                  <ParseContent content={acf.meta.description} />
                </div>
              </BlockDefault>
            </div>
            <Contact className="col-lg-5">
              <FormBlock>
                <div className="px-lg-3 pt-4">
                  <Title contrast>
                    {parse(acf.meta.contact_title)}
                  </Title>
                </div>
                <div className="px-lg-2 mt-4 mt-lg-5">
                  <Stripe contrast />
                </div>
                <div className="px-lg-3 mt-4 mt-lg-5">
                  {language === 'nl_NL' ? (
                    <GravityForm vacature id={5} defaultValues={{ 'input_6': title }} />
                  ) : (
                    <GravityForm vacature id={6} defaultValues={{ 'input_6': title }} />
                  )}
                </div>
              </FormBlock>
            </Contact>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default PageTemplate
